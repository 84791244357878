import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import PrintContainer from '../PrintContainer';
import {
  Head, Sub, Table, Text, Alert,
} from '../../../elements';
import data from './printSpecsData.json';
import TableFromJson from '../../../components/TableFromJson';
import AppContainer from '../../../components/AppContainer';

const GridLayout = styled.section`
  max-width: 1650px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
const BoldAlert = styled(Alert)`
  font-weight: bold;
`;

const InfoSection = styled.div`
  padding: 1rem;
  border: 1px solid ${props => props.theme.bluegrey};
  margin-right: -1px;
  margin-bottom: -1px;
  h3 {
    font-size: 1.65rem;
    color: ${props => props.theme.dark};
  }
  @media screen and (max-width: 500px) {
    grid-column: 1;
    h3 {
      font-size: 1.35rem;
    }
  }
`;

const TableContainer = styled(InfoSection)`
  grid-column: span 2;
  overflow: scroll;
  table {
    min-width: 600px;
  }
  tr:nth-of-type(even) {
    background-color: ${props => props.theme.light};
  }
`;

const FontsAndType = ({ table }) => (
  <TableContainer>
    <h3>{table.heading}</h3>
    <Text>{table.subHeading}</Text>
    <TableFromJson table={table.childContentfulTableTableJsonNode} />
    <footer dangerouslySetInnerHTML={{ __html: table.footer }} />
  </TableContainer>
);

const Info = ({ item: { label, infoList, extraInfo } }) => (
  <InfoSection>
    <h3>{label}</h3>
    {infoList.map((line, i) => (
      <Text key={i} dangerouslySetInnerHTML={{ __html: line }} />
    ))}
    {extraInfo && <BoldAlert>{extraInfo}</BoldAlert>}
  </InfoSection>
);

const PrintSpecs = ({ data: { contentfulPage: page } }) => {
  return (
    <AppContainer pageTitle="Print Specs">
      <PrintContainer>
        <Head ariaRole="heading">{page.heading}</Head>
        <Sub>{page.subHeading}</Sub>
        {page.sections
          && page.sections.map(section => (
            <GridLayout>
              <FontsAndType table={section.table} />
              {section.items && section.items.map((item, i) => <Info key={i} item={item} />)}
            </GridLayout>
          ))}
      </PrintContainer>
    </AppContainer>
  );
};

export const printSpecsPageQuery = graphql`
         query PrintSpecsPageQuery($id: String!) {
           contentfulPage(id: { eq: $id }) {
             title
             heading
             subHeading
             sections {
               id
               heading
               subHeading
               content {
                 childContentfulRichText {
                   html
                 }
               }
               items {
                 id
                 label
                 infoList
                 extraInfo
                 component
               }
               table {
                 heading
                 childContentfulTableTableJsonNode {
                   internal {
                     content
                   }
                 }
               }
             }
           }
         }
       `;

export default PrintSpecs;
